import { Hints } from './Hints';
import { UserInput } from './UserInput';
import { Login } from './Login';
import { CustomWikiPopup } from './CustomWiki';
import useScreenSize from './useScreenSize';
import { Dropdown, Modal } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Instruction from "./Instruction";
import {D3Background} from "./D3background";
import Leaderboard from './Leaderboard';
import { LoadingContext, socket, HintsContext } from './App';
import Footer from './Footer';


export default function Multiplayer(){
    const screenSize = useScreenSize();
    const navigate = useNavigate();
    const {hints, set_hints} = useContext(HintsContext);
    const {loading, setLoading} = useContext(LoadingContext);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [showCustomPopup, setShowCustomPopup] = useState(false);
    const [showInstructionPopup, setShowInstructionPopup] = useState(false);
    const [in_room, set_in_room] = useState(false);
    const [room_name, set_room_name] = useState('');
    const [player_name, set_player_name] = useState('');
    const [role, set_role] = useState('create');
    const [invalid_input_room, set_invalid_input_room] = useState(false)
    const [invalid_input_player, set_invalid_input_player] = useState(false);
    const [invalid_num_games, set_invalid_num_games] = useState(false);
    const [all_players, set_all_players] = useState();
    const [num_games, set_num_games] = useState('');


    // useEffect(() => {
    //     if(in_room){
    //         const handleUnload = (e) => {
    //             e.returnValue = 'x'
    //         };

    //         window.addEventListener('beforeunload', handleUnload);

    //         return () => {
    //             window.removeEventListener('beforeunload', handleUnload);
    //         }

    //     }

    // }, [in_room]);

    useEffect(() => {
        const handleSendPlayers = (room) => {
            const playerList = Object.entries(room).map(([key, value]) =>  [key, value]);
            set_all_players(playerList);
        };

        const handleRemoved = (removed) => {
            if (removed){
                set_in_room(false);
            }
        };

        socket.on('send-players', handleSendPlayers);
        socket.on('removed', handleRemoved);
        return () => {
            socket.off('send-players', handleSendPlayers);
            socket.off('removed', handleRemoved);
        };
    }, []);

    const handleCustomPopupToggle = () => {
        setShowCustomPopup(!showCustomPopup);
    };

    const handleInstructionPopupToggle = () => {
        setShowInstructionPopup(!showInstructionPopup);
    };
    const toInfinitePage = () => {
        setSearchParams({'a': ''});
        navigate('/infinite');
        socket.disconnect();
    };

    const toMultiplayerPage = () => {
       //  navigate('/multiplayer');
       //  window.location.reload();
       //  socket.disconnect();
    };

    const toDailyPage = () => {
        setSearchParams({'a': ''});
        navigate('/daily'); 
        socket.disconnect();
    };

    const toHome = () => {
        setSearchParams({'a': ''});
        navigate('/');
        socket.disconnect();
    };

    const handleSocket = () => {
        if(role === "join"){
            
            socket.emit("joinRoom", room_name, player_name, (status) => {
                if (status === "Room not found" || status === "Game started"){
                    set_invalid_input_room(true);
                    setTimeout(() => {
                        set_invalid_input_room(false);
                    }, 500);
                }
                else if (status === "Name taken"){
                    set_invalid_input_player(true);
                    setTimeout(() => {
                        set_invalid_input_player(false);
                    }, 500);
                }
                else {
                    set_hints({
                        hidden: [],
                        all: []
                    });
                    set_in_room(true);
                }
            });
        }
        else if (role === "create"){

            if (num_games < 1 || num_games > 10 || !num_games || !Number(num_games)){
                set_invalid_num_games(true);
                setTimeout(() => {
                    set_invalid_num_games(false);
                }, 500);

                alert("Choose between 1 and 10 games")
            }
            else{
                socket.emit('createRoom', room_name, player_name, num_games, (status) => {
                    if (status === "Room exists"){
                        set_invalid_input_room(true);
                        setTimeout(() => {
                            set_invalid_input_room(false);
                        }, 500);
                    } 
                    else {
                        set_hints({
                            hidden: [],
                            all: []
                        });
                        set_in_room(true);
                    }
                });
            }
        }
    };

    return (
    <div>
        <div className='d-flex py-3 justify-content-between align-items-center sticky-top bg-white border-bottom header_background'>
        
            {
                screenSize.width > 680 ? 
                <>
                <div className='d-flex justify-content-center align-items-center' style={{width: "300px"}}>
                    
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-style btn bg-off-white" >
                            &#9776;
                        </Dropdown.Toggle>
                        
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={ toHome }> <i className="bi bi-house"></i> <> &nbsp; Home</> </Dropdown.Item>
                            <Dropdown.Item onClick={ toDailyPage }> <i className="bi bi-calendar"></i> <> &nbsp; Daily</> </Dropdown.Item>
                            <Dropdown.Item onClick={ toInfinitePage }> <i className='bi bi-infinity'></i><> &nbsp; Infinite</> </Dropdown.Item>
                            <Dropdown.Item onClick={ toMultiplayerPage }> <i className="bi bi-people"></i><> &nbsp; Multiplayer</> </Dropdown.Item>
                            <Dropdown.Item onClick={ handleCustomPopupToggle }> <i className="bi bi-plus-lg"></i> <> &nbsp; Make Your Own</> </Dropdown.Item>
                            <Dropdown.Item onClick={ handleInstructionPopupToggle }> <i className="bi bi-question"> </i> <>&nbsp; Instructions</></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className='d-flex justify-content-center align-items-start'>
                    <h1 className="text-center pt-2 fs-3">GuessTheWiki</h1>
                    <h2 className="text-center fs-5">Multiplayer</h2>
                </div>

                <div className='d-flex justify-content-center align-items-center' style={{width: "300px"}}>
                    <Login page={'multiplayer'}/>
                </div>

                </>
                :
                <>
                <div className='d-flex justify-content-center align-items-center pl'>
                    <div className='d-flex justify-content-center align-items-center px-2'>
                        <Dropdown>
                            <Dropdown.Toggle className="dropdown-style btn bg-off-white">
                                &#9776;
                            </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={ toHome }> <i className="bi bi-house"></i> <> &nbsp; Home</> </Dropdown.Item>
                            <Dropdown.Item onClick={ toDailyPage }> <i className="bi bi-calendar"></i> <> &nbsp; Daily</> </Dropdown.Item>
                            <Dropdown.Item onClick={ toInfinitePage }> <i className='bi bi-infinity'></i><> &nbsp; Infinite</> </Dropdown.Item>
                            <Dropdown.Item onClick={ toMultiplayerPage }> <i className="bi bi-people"></i><> &nbsp; Multiplayer</> </Dropdown.Item>
                            <Dropdown.Item onClick={ handleCustomPopupToggle }> <i className="bi bi-plus-lg"></i> <> &nbsp; Make Your Own</> </Dropdown.Item>
                            <Dropdown.Item onClick={ handleInstructionPopupToggle }> <i className="bi bi-question"> </i> <>&nbsp; Instructions</></Dropdown.Item>
                        </Dropdown.Menu>

                        </Dropdown>
                    </div>

                    <div className='d-flex flex-column justify-content-center align-items-start px-2'>
                        <h1 className="text-center pt-2 fs-4">GuessTheWiki</h1>
                        <h2 className="text-center fs-5">Multiplayer</h2>
                    </div>

                </div>

                <div className='d-flex justify-content-center align-items-center pr'>
                    <Login page={'multiplayer'}/>
                </div>
                </>
            }

        </div>
        <div className='app-container'>
            <div className='content'>

                { in_room ?

                    loading === 'leaderboard_only' ?   
                        <>
                        <br />
                        <br />
                        <Leaderboard player_name={player_name} all_players={all_players} room_name = {room_name} role = {role}/>
                        </>
                    :
                    <>                      
                        <>
                        <br />
                        <br />

                        <Hints page={'multiplayer'}/>
                        <UserInput page={'multiplayer'}/>
                        </>

                        <Leaderboard player_name={player_name} all_players={all_players} room_name = {room_name} role = {role}/>

                    
                    </>
                    
                :   

                <div className='d-flex flex-column justify-content-center align-items-center shadow-lg rounded w-50 position-absolute top-50 start-50 translate-middle p-3' style={{gap: '20px', backgroundColor: 'white'}}>
                    <div className="d-flex" style={{gap: "1px"}} >
                        <button 
                            className="btn p-l border border-1" 
                            style={{ backgroundColor: `${role === 'create' ? "white": "rgb(240,240,240)"}`}} 
                            type="button" onClick={ () => set_role('create')}
                        > 
                            Create Room 
                        </button>

                        <button 
                            className="btn p-l border border-1" 
                            style={{ backgroundColor: `${role === 'join' ? "white": " rgb(240,240,240)"}`}} 
                            type="button" onClick={ () => set_role('join')}
                        > 
                            Join Room 
                        </button>
                    </div>
                    <div className='p-2 d-flex flex-column justify-content-center align-items-center' style={{gap: "15px"}}>
                        <input type ="text"
                            value={player_name}
                            onChange = {(e) => set_player_name(e.target.value)}
                            placeholder='Player Name'
                            style={{minWidth: '150px',width: 'fit-content', maxWidth: '50%'}}
                            className= {`text-center p1 ${invalid_input_player ? "shake" : ""}`} />

                        <input type ="text"
                            value={room_name}
                            onChange = {(e) => set_room_name(e.target.value)}
                            placeholder='Room Name'
                            style={{minWidth: '150px',width: 'fit-content', maxWidth: '50%'}}
                            className= {`text-center p1 ${invalid_input_room ? "shake" : ""}`} />

                        {
                            role === 'create' ?

                            <input type ="text"
                                value={num_games}
                                onChange = {(e) => set_num_games(e.target.value)}
                                placeholder='Number of games'
                                style={{minWidth: '150px',width: 'fit-content', maxWidth: '50%'}}
                                className= {`text-center p1 ${invalid_num_games ? "shake" : ""}`} />

                            :
                            <></>

                        }

                        <button hidden = {player_name === '' || room_name === ''} className='btn px-2 bg-off-white' onClick={handleSocket}> Submit </button>
                    </div>
                </div>
                    
                
                }

                <Modal show={showCustomPopup} onHide={handleCustomPopupToggle} centered className='position-absolute top-50 start-50  translate-middle w-75'>
                    
                    <Modal.Header closeButton>
                    </Modal.Header>

                    <Modal.Body className='d-flex justify-content-center align-items-center'>
                        <CustomWikiPopup/>
                    </Modal.Body>

                </Modal>

                <Modal show={showInstructionPopup} onHide={handleInstructionPopupToggle} centered className='position-absolute start-50 top-50  translate-middle w-75'>
                    
                    <Modal.Header closeButton>
                        <Modal.Title>
                            How to Play
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='d-flex justify-content-center align-items-center'>
                        <Instruction />
                    </Modal.Body>

                </Modal>


            </div>
            {
                screenSize.width < 680 ?
                <></> : <D3Background  />
            }

            <Footer />

        </div>

    </div>
    );
}
