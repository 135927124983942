import { useState } from 'react';
import { api_url } from './App';

const base = window.location.origin;

export function CustomWikiPopup() {
  /*
    Component for generating custom guessthewiki game from a wikipedia page title or link to wiki.
    Is displayed in a Modal React Bootstrap element.
    */
  const [input_val, set_input_value] = useState('');
  const [invalid_input, set_invalid_input] = useState(false);
  const [input_type, set_input_type] = useState('word'); // what format the input is
  const [customLink, setCustomLink] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(api_url + '/verify_wiki', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: input_type,
        content: input_val,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result === 'False') {
          // the wikipedia is not valid for guessthewiki
          set_invalid_input(true);

          setTimeout(() => {
            set_invalid_input(false);
          }, 500);
        } else if (result === 'Invalid') {
          alert('Server error verifying wiki. Please try again.');
        } else {
          let link = "/infinite/?a=" + result
          setCustomLink(link);
        }
      });
  };

  return (
    <form className="d-flex flex-column justify-content-center align-items-center">
      <div className="text-center fs-3">Make Your Own Game</div>

      {customLink === "" ? 
      <>
        <br />

        <div className="d-flex" style={{ gap: '1px' }}>
          <button
            className="btn p-l border border-1"
            style={{ backgroundColor: `${input_type === 'word' ? 'white' : 'rgb(240,240,240)'}` }}
            type="button"
            onClick={() => set_input_type('word')}
          >
            {' '}
            Title{' '}
          </button>
          <button
            className="btn p-l border border-1"
            style={{ backgroundColor: `${input_type === 'link' ? 'white' : ' rgb(240,240,240)'}` }}
            type="button"
            onClick={() => set_input_type('link')}
          >
            {' '}
            Link{' '}
          </button>
        </div>
        <div className="p-2">
          {input_type === 'link' ? (
            <input
              type="text"
              value={input_val}
              onChange={(e) => set_input_value(e.target.value)}
              placeholder="WikiPedia Link"
              className={`text-center ${invalid_input ? 'shake' : ''}`}
            />
          ) : (
            <input
              type="text"
              value={input_val}
              onChange={(e) => set_input_value(e.target.value)}
              placeholder="WikiPedia Title"
              className={`text-center ${invalid_input ? 'shake' : ''}`}
            />
          )}
        </div>
        <br />

        <div className="container w-75 d-flex justify-content-center p-2">
          <button type="submit" className="btn bg-off-white" onClick={handleSubmit}>
            Generate Game
          </button>
        </div>
      </>
      :
      <>
        <div className="container w-75 d-flex justify-content-center p-2 mt-3">
          <a href={customLink} className="btn bg-off-white">
            Go To Custom Game
          </a>
        </div>
        <div className="container w-75 d-flex justify-content-center p-2">
          <button type="submit" className="btn bg-off-white"
            onClick={() => {navigator.clipboard.writeText(base + customLink)}}
            >
            <i class="bi bi-link-45deg"></i>
          </button>
        </div>
      </>
      }
    </form>
  );
}

